<template>
  <dash-page-new
      :root="$store.state.newsletter.emailPageData.root || $t('Newsletter')"
      :root-location="businessDashLink($store.state.newsletter.emailPageData.rootLocation ||'newsletter')"
      :subtitle="$store.state.newsletter.emailPageData.subtitle || $t('Email')"

      :title="$store.state.newsletter.emailPageData.title || $t('Newsletter')"
      :icon="$store.state.newsletter.emailPageData.icon || 'mdi-email'"
      :filters="$store.state.newsletter.emailPageData.filters || {}"
      :header-route="$store.state.newsletter.emailPageData.headerRoute || null"
      :filters-input="$store.state.newsletter.emailFiltersData || []"
      :side-navigation="$store.state.newsletter.emailNavigationSelect"
      @filter="$store.state.newsletter.emailFiltersData = $event"
      navigation-route="newsletter/email"
      navigation-return-route="newsletter"

      no-side >


    <template #header_action.desktop>

    </template>


    <template #header_action>

      <v-btn v-if="$store.state.newsletter.emailPageData.headerAction"
             @click="$store.state.newsletter.emailPageData.headerAction()"
             :color="wsATTENTION"
             class="noCaps"
             :disabled="!$store.state.newsletter.emailReadyToPublish && $store.state.newsletter.emailPageData.headerActionText === $t('Send')"
             :dark="!(!$store.state.newsletter.emailReadyToPublish && $store.state.newsletter.emailPageData.headerActionText === $t('Send'))">
        {{ $store.state.newsletter.emailPageData.headerActionText }}
      </v-btn>
    </template>

    <template #default>
      <router-view></router-view>



    </template>


  </dash-page-new>
</template>

<script>
export default {
  name: "BusinessNewsletter",
  computed : {
    navigationItems() {
      return [
        // { text : this.$t('Campaign')       , value : 'crm'       , path : 'crm'       , icon : "mdi-card-account-phone-outline" },
        { text : this.$t('Newsletters')       , value : ''     , path : ''     , icon : "mdi-email-newsletter" },
        { text : this.$t('Templates')         , value : 'templates' , path : 'templates' , icon : "mdi-pencil-ruler" },
        { text : this.$t('AutomatedLetters')  , value : 'auto'      , path : 'auto'    , icon : "mdi-flash-auto" },
        { text : this.$t('Settings')          , value : 'settings'  , path : 'settings'  , icon : "mdi-cogs" },
      ]
    }
  },
  methods : {

    async initPage() {
      this.$store.state.newsletter.emailNavigationSelect = this.navigationItems
    }

  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>